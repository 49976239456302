<template>
  <div class="p-3 orders-list-holder">
    <OrderDetails></OrderDetails>
  </div>
</template>

<script>
import OrderDetails from '@/components/OrderManagement/Orders/OrderDetails.vue';

export default {
  name: 'ShipVia.vue',
  components: {
    OrderDetails,
  },
};
</script>

<style lang="scss">
  @import '@/styles/OrderManagement.scss';
</style>
