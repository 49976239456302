<template>
  <b-dropdown text="Status Change" variant="outline-dark" :disabled="isLoading">
    <b-dropdown-item
      v-for="status in statuses"
      @click="updateOrderStatus(status)"
      :key="status">
        {{ translateStatus(status) }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import axios from 'axios';
import translations from '@/translations';

export default {
  name: 'order-status-list',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      translations: translations.orders,
      allStatuses: [],
    };
  },
  computed: {
    statuses() {
      return this.allStatuses.filter(status => status !== this.order.status);
    },
  },
  async beforeMount() {
    await this.fillAvailableOrderStatusesByOrderType();
  },
  methods: {
    translateStatus(key) {
      return this.order.type === 'return'
        ? translations.returns.status[key] || key
        : translations.orders.status[key] || key;
    },
    async fillAvailableOrderStatusesByOrderType() {
      return new Promise(resolve => {
        axios({
          url: `v1/order/types/${this.order.type}/status-list`,
          method: 'GET',
          responseType: 'json',
        }).then(response => {
          this.allStatuses = response.data.statuses;
          resolve();
        }).catch(err => {
          this.$noty.error('Unable to load available status list: ', err);
          this.allStatuses = [];
          resolve();
        });
      });
    },
    async updateOrderStatus(status) {
      this.isLoading = true;
      await axios.patch(`v1/order/${this.order.order_id}/force-update-status`, { status }).then(() => {
        this.$emit('statusChanged', status);
        this.$noty.success(`Order status changed to ${this.translations.status[status] || status}`);
      }).catch(error => {
        let notyMsg = 'Failed to update order status';
        if (error.response) {
          notyMsg = `${error.response.data.message || notyMsg} (Error ${error.response.status})`;
        }
        this.$noty.error(notyMsg);
      }).finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>
