<template>
  <b-container class="mt-4 orders-list-holder card_orderslist">
    <b-spinner v-if="loading"></b-spinner>
    <div v-else>
      <b-row>
        <div class="sku-exceptions-holder col">
          <header class="mb-4">
            <BackButton class="exception-BackButton"></BackButton>
            <h3>{{ translations.type[order.type] }} Details - {{ order.order_id }}</h3>
            <h3 v-for="tag in order.tags" :key="tag.id">
              <b-badge pill href="#" class="ml-3" variant="warning">
                {{ translations.tags[tag.tag]}}
              </b-badge>
            </h3>
          </header>
        </div>
        <div class="d-flex justify-content-end col">
          <b-dropdown v-if="showActions()" text="Actions" variant="outline-dark" class="m-2">
            <orderAllowedActions :order="order" :excludeDetails="true">
            </orderAllowedActions>
          </b-dropdown>
          <orderStatusList
            v-if="canForceChangeOrderStatus || $isSudo"
            :order="order"
            @statusChanged="handleStatusChanged"
            class="mt-2 mb-2"></orderStatusList>
        </div>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-card class="mb-4 order-info">
            <template #header>
              <h5 class="mb-0">{{ translations.type[order.type] }} Info</h5>
            </template>
            <div class="section">
              <div class="col">
                <h6>{{ translations.type[order.type] }} order ID</h6>
                <p>{{ order.order_id }}</p>
              </div>
              <div class="col">
                <h6>Created at</h6>
                <p>{{ order.created_at }}</p>
              </div>
              <div class="col">
                <h6>Updated at</h6>
                <p>{{ order.updated_at }}</p>
              </div>
              <div class="col">
                <h6>
                  <span style="cursor: pointer; color: #007bff; text-decoration: none"
                    @click="toggleStatusTimelineVisibility">Status</span>
                </h6>
                <div class="div_clex_name orders-colors mt-3">
                  <span :class="[order.status, 'clex_name']">
                    {{ translations.status[order.status] || order.status }}
                  </span>
                </div>
              </div>
            </div>
            <div class="section" v-if="order.type === types.FORWARD">
              <div class="col">
                <h6>SKU</h6>
                <p>{{ order.sku }}</p>
              </div>
              <div class="col">
                <h6>Type</h6>
                <p>{{ order.component_type }}</p>
              </div>
              <div class="col">
                <h6>Product type</h6>
                <p>{{ order.product_type }}</p>
              </div>
              <div class="col">
                <h6>Method</h6>
                <p>{{ order.method }}</p>
              </div>
            </div>
            <div class="section" v-if="order.type === types.FORWARD">
              <div class="col">
                <h6>Serial Number</h6>
                <p>{{ getKitSerialNumber }}</p>
              </div>
              <div class="col">
                <h6>Error Reason</h6>
                <p>
                  {{ getSanitizedErrorReason }}
                </p>
              </div>
              <div class="col">
                <h6>Delivery date</h6>
                <p>
                  {{
                  order.tracking && order.tracking.shipment_status === "Delivered"
                  ? formatDate(order.tracking.updated_at)
                  : 'N/A'
                  }}
                </p>
              </div>
              <div class="col">
                <h6>Support ticket</h6>
                <p v-if="order.support_ticket_url">
                  <a :href="order.support_ticket_url" target="_blank">
                    Zendesk link
                  </a>
                </p>
                <p v-else>N/A</p>
              </div>
            </div>
            <div class="section" v-if="order.type === types.FORWARD">
              <div class="col" v-if="order.method === 'manual'">
                <div class="d-flex">
                  <h6>Reason</h6>
                  <div id="info-reason-description" v-if="!!order.reason_description">
                    <b-icon icon="info-circle" scale="1.0" class="ml-2"></b-icon>
                  </div>
                  <b-tooltip
                      id="info-reason-description-tooltip"
                      target="info-reason-description"
                      triggers="hover"
                      placement="top">
                    {{ order.reason_description }}
                  </b-tooltip>
                </div>
                <p>{{ order.reason }}</p>
              </div>
              <div class="col" v-if="order.replaced_order_id">
                <h6>Replacing Order</h6>
                <b-button class="text-left pl-0" variant="link" :to="`/orders/${order.replaced_order_id}`">
                  {{ order.replaced_order_id }}
                </b-button>
              </div>
              <div class="col" v-if="order.replacing_order_id">
                <h6>Replaced by</h6>
                <b-button class="text-left pl-0" variant="link" :to="`/orders/${order.replacing_order_id}`">
                  {{ order.replacing_order_id }}
                </b-button>
              </div>
              <div class="col" v-if="showReturnOrderID()">
                <h6>Return Order ID</h6>
                <b-button class="text-left pl-0" variant="link" :to="`/orders/${order.order_return.order_id}`">
                  {{ order.order_return.order_id }}
                </b-button>
              </div>
              <div :class="getDynamicColumnSize()"></div>
            </div>
            <div class="section" v-if="order.type === types.RETURN">
              <div class="col">
                <h6>Return trigger</h6>
                <p>{{ order.trigger_reason || 'N/A' }}</p>
              </div>
              <div class="col">
                <h6>Returned Date</h6>
                <p>{{ getReturnedDate() }}</p>
              </div>
              <div class="col">
                <h6>Error Reason</h6>
                <p>
                  {{ getSanitizedErrorReason }}
                </p>
              </div>
              <div class="col">
                <h6>Forward Order ID</h6>
                <b-button v-if="order.order_return"
                          class="text-left pl-0" variant="link" :to="`/orders/${order.order_return.order_forward_id}`">
                  {{ order.order_return.order_forward_id }}
                </b-button>
                <p v-else>N/A</p>
              </div>
            </div>
            <div class="section" v-if="order.type === types.FORWARD">
              <div class="col">
                <h6>Pro forma file</h6>
                <b-button class="mt-3" variant="primary"
                  v-if="order.distribution_info && order.distribution_info.pro_forma_file_name" target="_blank"
                  @click="downloadDistributionFile(order.order_id, 'pro_forma')">
                  Download
                </b-button>
                <p v-else>N/A</p>
              </div>
              <div class="col">
                <h6>Shipping label file</h6>
                <b-button class="mt-3" variant="primary"
                  v-if="order.distribution_info && order.distribution_info.shipping_label_file_name" target="_blank"
                  @click="downloadDistributionFile(order.order_id, 'shipping_label')">
                  Download
                </b-button>
                <p v-else>N/A</p>
              </div>
              <div class="col">
                <h6>Return label file</h6>
                <b-button class="mt-3" variant="primary"
                  v-if="order.distribution_info && order.distribution_info.return_label_file_name" target="_blank"
                  @click="downloadDistributionFile(order.order_id, 'return_label')">
                  Download
                </b-button>
                <p v-else>N/A</p>
              </div>
              <div class="col" v-if="order.unit === units.bloom">
                <h6>Pod version</h6>
                <p v-if="order.sku_info">{{ order.sku_info.pod_version }}</p>
                <p v-else> N/A </p>
              </div>
              <div class="col" v-else>
              </div>
            </div>
          </b-card>
          <b-card class="mb-4 client-info">
            <template #header>
              <h5 class="mb-0">Patient {{ `&` }} Client Info</h5>
            </template>
            <div class="section">
              <div class="col">
                <h6>Program ID</h6>
                <p>{{ order.patient_id }}</p>
              </div>
              <div class="col">
                <h6>Patient Name</h6>
                <p>{{ order.patient_name }}</p>
              </div>
              <div class="col">
                <h6>Email</h6>
                <p>{{ order.patient_email }}</p>
              </div>
              <div class="col">
                <h6>Phone Number</h6>
                <p>{{ order.patient_phone_number }}</p>
              </div>
            </div>
            <div class="section">
              <div class="col">
                <h6>Institution Name</h6>
                <p>{{ order.institution_name || 'N/A' }}</p>
              </div>
              <div class="col">
                <h6>Institution ID</h6>
                <p>{{ order.institution_id }}</p>
              </div>
              <div class="col">
                <h6>Client Name</h6>
                <p>{{ order.client_name }}</p>
              </div>
              <div class="col">
                <h6>Client ID</h6>
                <p>{{ order.client_id }}</p>
              </div>
            </div>
            <div class="section">
              <div class="col">
                <h6>Account UUID</h6>
                <p>{{ order.account_uuid }}</p>
              </div>
            </div>
          </b-card>
          <b-card class="mb-4">
            <template #header>
              <h5 class="mb-0">Shipping Info</h5>
            </template>
            <div class="section">
              <div class="col">
                <h6>Country</h6>
                <p>{{ order.patient_country }}</p>
              </div>
              <div class="col">
                <h6>City</h6>
                <p>{{ order.patient_city }}</p>
              </div>
              <div class="col">
                <h6>State</h6>
                <p>{{ order.patient_state }}</p>
              </div>
              <div class="col">
                <h6>Zip Code</h6>
                <p>{{ order.patient_zip_code }}</p>
              </div>
            </div>
            <div class="section" v-if="order.type === types.FORWARD">
              <div class="col">
                <h6>Ship From</h6>
                <p>{{ order.ship_from }}</p>
              </div>
              <div class="col">
                <h6>Ship Via</h6>
                <p>{{ order.ship_via }}</p>
              </div>
              <div class="col">
                <h6>Address line 1</h6>
                <p>{{ order.patient_address_1 }}</p>
              </div>
              <div class="col">
                <h6>Address line 2</h6>
                <p>{{ order.patient_address_2 }}</p>
              </div>
            </div>
            <div class="section" v-if="order.type === types.RETURN">
              <div class="col">
                <h6>Address line 1</h6>
                <p>{{ order.patient_address_1 }}</p>
              </div>
              <div class="col">
                <h6>Ship Via</h6>
                <p>{{ order.ship_via }}</p>
              </div>
              <div class="col tracking-wrapper">
                <h6>Tracking number</h6>
                <div v-if="order.trackings">
                  <ul class="pl-0 list-unstyled">
                    <li v-for="tracking in order.trackings" :key="tracking.id" class="mt-3">
                      <a :href="tracking.tracking_link" target="_blank">
                        {{ tracking.tracking_number }}
                      </a>
                      <b-button size="sm mr-2" variant="light" class="btn-action"
                        @click="copyTrackingLink(tracking.tracking_link)">
                        <feather type="clipboard"></feather>
                      </b-button>
                    </li>
                  </ul>
                </div>
                <p v-else>N/A</p>
              </div>
              <div class="col return-label-wrapper">
                <h6>Return label</h6>
                <div v-if="order.trackings">
                  <ul class="pl-0 list-unstyled">
                    <li v-for="(tracking, index) in order.trackings" :key="index" class="mt-3">
                      <b-button variant="primary" v-if="tracking.order_tracking_label"
                        :href="tracking.order_tracking_label.shipping_label_url" target="_blank"
                        class="download-shipping-label">
                        Download
                      </b-button>
                    </li>
                  </ul>
                </div>
                <p v-else>N/A</p>
              </div>
            </div>
            <div class="section" v-if="order.type === types.FORWARD">
              <div class="col">
                <h6>Tracking number</h6>
                <div v-if="order.tracking">
                  <a :href="order.tracking.tracking_link" target="_blank" class="mt-3">
                    {{ order.tracking.tracking_number }}
                  </a>
                  <b-button size="sm mr-2" variant="light" class="btn-action"
                    @click="copyTrackingLink(order.tracking.tracking_link)">
                    <feather type="clipboard"></feather>
                  </b-button>
                </div>
                <p v-else>N/A</p>
              </div>
              <div class="col-9">
                <h6>Shipping Status</h6>
                <div class="div_clex_name mt-3" v-if="order.tracking && order.tracking.shipment_status">
                  <span class="align-left clex_name clex_name_color_shipped">{{ order.tracking.shipment_status }}</span>
                </div>
                <p v-else>N/A</p>
              </div>
            </div>
            <div class="section" v-if="order.type === types.RETURN">
              <div class="col">
                <h6>Shipping Status</h6>
                <div class="div_clex_name mt-3" v-if="order.tracking && order.tracking.shipment_status">
                  <span class="align-left clex_name clex_name_color_shipped">{{ order.tracking.shipment_status }}</span>
                </div>
                <p v-else>N/A</p>
              </div>
            </div>
          </b-card>
          <b-card class="mb-4" v-if="order.type === types.RETURN && order.sender_address">
            <template #header>
              <h5 class="mb-0">Start Location</h5>
            </template>
            <div class="section">
              <div class="col">
                <h6>Country</h6>
                <p>{{ order.sender_address.country }}</p>
              </div>
              <div class="col">
                <h6>City</h6>
                <p>{{ order.sender_address.city }}</p>
              </div>
              <div class="col">
                <h6>State</h6>
                <p>{{ order.sender_address.state }}</p>
              </div>
              <div class="col">
                <h6>Zip Code</h6>
                <p>{{ order.sender_address.zip_code }}</p>
              </div>
            </div>
            <div class="section">
              <div class="col">
                <h6>Address Line 1</h6>
                <p>{{ order.sender_address.line_1 }}</p>
              </div>
            </div>
          </b-card>
          <b-card v-if="order.type === types.RETURN
            && (order.pickup_schedules != null && order.pickup_schedules.length > 0)" id="pickup-schedule-details">
            <template #header>
              <h5 class="mb-0">Pickup Info</h5>
            </template>
            <div class="section">
              <div class="col">
                <h6>Created At</h6>
                <p>{{ formatDate(order.pickup_schedules[0].created_at) }}</p>
              </div>
              <div class="col">
                <h6>Updated At</h6>
                <p>{{ formatDate(order.pickup_schedules[0].updated_at) }}</p>
              </div>
              <div class="col">
                <h6>Pickup Request Number</h6>
                <p>{{ order.pickup_schedules[0].pickup_request_number }}</p>
              </div>
              <div class="col">
                <h6>Pickup Status</h6>
                <p>{{ order.pickup_schedules[0].status }}</p>
              </div>
            </div>
            <div class="section">
              <div class="col">
                <h6>Address Line</h6>
                <p>{{ order.pickup_schedules[0].address_line_1 }}</p>
              </div>
              <div class="col">
                <h6>City</h6>
                <p>{{ order.pickup_schedules[0].city }}</p>
              </div>
              <div class="col">
                <h6>State</h6>
                <p>{{ order.pickup_schedules[0].state }}</p>
              </div>
              <div class="col">
                <h6>Postal Code</h6>
                <p>{{ order.pickup_schedules[0].zip_code }}</p>
              </div>
            </div>
            <div class="section">
              <div class="col">
                <h6>Availability</h6>
                <p>{{ order.pickup_schedules[0].pickup_interval }}</p>
              </div>
              <div class="col-9">
                <h6>Pickup Date</h6>
                <p>{{ formatDate(order.pickup_schedules[0].pickup_date) }}</p>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <modalStatusTimeline :is-visible="isStatusTimelineVisible" :statuses="order.order_status" :type="order.type"
      @close="toggleStatusTimelineVisibility">
    </modalStatusTimeline>
  </b-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import translations from '@/translations';
import BackButton from '@/components/BackButton.vue';
import orderHandler from '@/mixins/orderHandler';
import { ORDER_STATUS, ORDER_TYPE, UNIT_BLOOM } from '@/scripts/constants';
import copyToClipboard from '@/scripts/tools/accessibility';
import modalStatusTimeline from './ModalStatusTimeline.vue';
import orderAllowedActions from './OrderAllowedActions.vue';
import orderStatusList from './OrderStatusList.vue';

export default {
  name: 'orders-details',
  data() {
    return {
      order: {
        order_id: null,
        client_id: null,
        client_name: null,
        institution_id: null,
        institution_name: null,
        patient_id: null,
        patient_name: null,
        patient_phone_number: null,
        patient_address_1: null,
        patient_address_2: null,
        patient_city: null,
        patient_state: null,
        patient_zip_code: null,
        patient_country: null,
        patient_email: null,
        ship_from: null,
        product_type: null,
        component_type: null,
        reason: null,
        reason_description: null,
        tracking_number: null,
        shipping_status: null,
        sku: null,
        status: null,
        method: null,
        serial_number: null,
        ship_via: null,
        created_at: null,
        updated_at: null,
        support_ticket_url: null,
        replaced_order_id: null,
        replacing_order_id: null,
        type: '',
        tracking: null,
        trigger_reason: null,
        order_return: {
          order_forward_id: null,
          return_label: [],
        },
        trackings: [],
        order_status: [],
        pickup_schedules: [],
        distribution_info: null,
        production_info: null,
        sender_address: null,
      },
      loading: false,
      translations: translations.orders,
      types: ORDER_TYPE,
      status: ORDER_STATUS,
      units: {
        bloom: UNIT_BLOOM,
      },
      isStatusTimelineVisible: false,
      showChangeOrderStatusModal: false,
      canForceChangeOrderStatus: false,
    };
  },
  async beforeMount() {
    await Promise.all([
      this.getOrderDetails(),
      this.loadCanForceChangeOrderStatus(),
    ]);
  },
  mixins: [ orderHandler ],
  components: {
    BackButton,
    modalStatusTimeline,
    orderAllowedActions,
    orderStatusList,
  },
  computed: {
    getLastReturnLabel() {
      return this.order.order_return && this.order.order_return.return_label.length
        ? this.order.order_return.return_label[0]
        : null;
    },
    getSanitizedErrorReason() {
      return this.order.order_status && this.order.order_status.length !== 0
        ? this.order.order_status[0].error_reason
        : 'N/A';
    },
    getKitSerialNumber() {
      if (this.order.tracking && this.order.tracking.kit_serial_number) {
        return this.order.tracking.kit_serial_number;
      }

      if (this.order.production_info && this.order.production_info.kit_serial_number) {
        return this.order.production_info.kit_serial_number;
      }

      return 'N/A';
    },
  },
  methods: {
    async getOrderDetails() {
      this.loading = true;

      const params = {
        with_replacing_order: true,
        with_addresses: this.order.type === ORDER_TYPE.RETURN,
      };

      await axios
        .get(`v1/order/${this.$route.params.orderID}`, { params })
        .then(response => {
          this.order = response.data.order;
        })
        .catch(err => {
          this.$noty.error('Unable to load orders: ', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatDate(value) {
      if (!value) {
        return '-';
      }
      return moment(value).utc().format('YYYY-MM-DD');
    },
    getDynamicColumnSize() {
      let totalColumns = 12;

      if (this.order.method === 'manual') {
        totalColumns -= 3;
      }
      if (this.order.replaced_order_id) {
        totalColumns -= 3;
      }
      if (this.order.replacing_order_id) {
        totalColumns -= 3;
      }
      if (this.order.order_return) {
        totalColumns -= 3;
      }

      return `col-${totalColumns}`;
    },
    showActions() {
      return this.hasActions(this.order.actions);
    },
    toggleStatusTimelineVisibility() {
      this.isStatusTimelineVisible = !this.isStatusTimelineVisible;
    },
    showReturnOrderID() {
      return this.order.order_return && this.order.order_return.order_id;
    },
    async downloadDistributionFile(orderID, fileType) {
      axios({
        url: `v1/orders/${orderID}/distribution-info/${fileType}`,
        method: 'GET',
        responseType: 'json', // Receive the response as JSON
      })
        .then(response => {
          // Extract the data from the JSON response
          const { fileContent, fileName } = response.data;

          // Decode the Base64 file content back to binary
          const binaryContent = window.atob(fileContent);

          // Convert the binary content to a Blob
          const blob = new Blob([ new Uint8Array([ ...binaryContent ].map(char => char.charCodeAt(0))) ], { type: 'application/pdf' });

          // Create a link and trigger the download
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          console.error(err);
          this.$noty.error('Unable to download the file: ', err.message);
        });
    },
    copyTrackingLink(link) {
      copyToClipboard(link);
      this.$noty.success('Tracking URL copied to clipboard');
    },
    getReturnedDate() {
      const totalStatus = this.order.order_status.length;
      if (totalStatus === 0) {
        return 'N/A';
      }

      for (let i = 0; i < totalStatus; i++) {
        if (this.order.order_status[i].status === ORDER_STATUS.Returned) {
          return this.formatDate(this.order.order_status[i].created_at);
        }
      }

      return 'N/A';
    },
    async loadCanForceChangeOrderStatus() {
      axios({
        url: 'v1/order/can-force-update-status',
        method: 'GET',
        responseType: 'json',
      }).then(response => {
        this.canForceChangeOrderStatus = response.data.can_force_update;
      }).catch(err => {
        console.error(err);
        this.canForceChangeOrderStatus = false;
      });
    },
    toggleChangeOrderStatusModal() {
      this.showChangeOrderStatusModal = !this.showChangeOrderStatusModal;
    },
    handleStatusChanged(status) {
      this.order.status = status;
      this.getOrderDetails();
    },
  },
  watch: {
    '$route.params.orderID': {
      handler() {
        this.getOrderDetails();
      },
    },
  },
};
</script>

<style>
.section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
}

p {
  margin-top: 1rem;
  word-break: break-word;
}

h6 {
  height: 1rem;
}
</style>
