<template>
  <b-modal
    centered
    size="lg"
    :visible="isVisible"
    :title="title"
    :ok-only="true"
    ok-title="Close"
    ok-variant="secondary"
    @close="close"
    @ok="close">
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="statuses">
            <h3>Timeline</h3>
            <ul class="timeline">
              <li class="mb-5"
                  v-for="(elem, index) in statuses" :key="index"
                  :class="{ 'active': index === 0 }">
                <div class="status">
                  <div class="status-date">
                    {{ parseDate(elem.created_at) }}
                  </div>
                  <div class="status-title">
                    <b>
                      {{ translations.status[elem.status] || elem.status }}
                    </b>
                    </div>
                  <div v-if="elem.error_reason" class="status-error-reason">
                    {{ elem.error_reason }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment/moment';
import translations from '@/translations';
import { ORDER_TYPE } from '@/scripts/constants';


export default {
  name: 'ModalStatusTimeline',
  data() {
    return {};
  },
  props: {
    statuses: Array,
    isVisible: Boolean,
    type: String,
  },
  computed: {
    title() {
      return this.type === ORDER_TYPE.FORWARD ? 'Order Status' : 'Return Status';
    },
    translations() {
      return this.type === ORDER_TYPE.FORWARD ? translations.orders : translations.returns;
    },
  },
  methods: {
    parseDate(date) {
      return date ? moment(date).utc().format('DD/MM/YYYY HH:mm') : '-';
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>

.statuses {
  overflow-y: auto;
  overflow-x: hidden;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 60%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

ul.timeline > li.active::before {
  background-color: #22c0e8;
}

.timeline .status {
  margin-left: 25px;
}

.timeline .status-date {
  flex: 1 1 auto;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-right: 20px;
}

.timeline .status-title {
  flex: 4 4 auto;
  font-size: 16px;
  color: #666;
}

</style>
